@import 'fonts';
@import 'metrics';
@import 'variables';
@import 'palette';
@import 'typography';
@import 'animations';
@import 'wrappers';
@import 'images';
@import 'forms';
@import 'tooltip';
@import 'editorjs';

html {
  font-size: var(--size--font-base);
}

body {
  font-family: $font-family-base !important;
  color: var(--color--text);
  background-color: var(--color--background-light);
}

input,
textarea {
  font-family: $font-family-base !important;
}

::-webkit-scrollbar {
  background-color: var(--color--background-light);
  width: var(--size--spacing-x4);
}

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
  background-color: var(--color--background-light);
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: var(--size--spacing-x4);
  border: var(--size--spacing-x1) solid #fff;
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
  display:none;
}

$headings: 1, 2, 3, 4, 5, 6, 7;

@each $h in $headings {
  h#{$h} {
    font-weight: bold;
    font-size: var(--size--font-heading-#{$h});
    line-height: var(--size--lineheight-heading-#{$h});
    letter-spacing: var(--size--letter-spacing-tight);
    margin-bottom: var(--size--spacing-x2);
  }
}

a {
  color: var(--color--text-link);
  font-weight: 400;
  letter-spacing: var(--size--letter-spacing-close);
  text-decoration: none;
  &:hover {
    text-decoration-skip-ink: auto;
    text-decoration: underline;
  }
}

.coming-soon {
  /*
  position: relative;
  z-index: 1;
  filter: blur(0);

  &:after {
    content: 'Coming Soon';
    display: flex;
    position: absolute;
    left: 0;
    top: 0;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    z-index: 100;
    backdrop-filter: blur(.25rem);
    -webkit-backdrop-filter: blur(.25rem);
    background-color: rgb(224 224 224 / 75%);
    border-radius: inherit;
    text-shadow: .125rem .125rem .125rem rgb(255, 255, 255, 0.7);
    font-size: var(--size--font-heading-1);
    font-weight: bold;
    padding: .625rem 0;
    box-sizing: border-box;
  }
  */
}

.coming-soon-clear {
  /*
  position: relative;

  &:after {
    content: 'Coming Soon';
    display: flex;
    position: absolute;
    left: 0;
    top: 0;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-size: var(--size--font-heading-3);
    font-weight: bold;
    padding: .625rem 0;
  }
  */
}
