:root {

  // Logo

  --image--nav-logo: url('~src/assets/images/arkius/logo-negative.svg');
  --image--nav-logo-positive: url('~src/assets/images/arkius/logo-positive.svg');
  --image--header-logo: url('~src/assets/images/arkius/logo-light.svg');
  --image--stripe-logo: url('~src/assets/images/arkius/powered-by-stripe.svg');

  // Metamask

  --image--metamask-logo: url('~src/assets/icons/logo-metamask.svg');

  // Backgrounds

  --image--background-login: url('~src/assets/images/arkius/signup-bg.png');
  --image--background-login-color: url('~src/assets/images/arkius/signup-bg-color.png');

  --image--background-logo: url('~src/assets/icons/logo-arkius.svg');
  --image--background-vm: url('~src/assets/images/arkius/logo-positive.svg');

  --image--background-onboarding-1: url('~src/assets/images/arkius/onboarding/hero_1.png');
  --image--background-onboarding-2: url('~src/assets/images/arkius/onboarding/hero_2.png');
  --image--background-onboarding-3: url('~src/assets/images/arkius/onboarding/hero_3.png');

  // Membership

  --image--nft-cover: url('~src/assets/images/arkius/membership/nft-cover.png');


}
