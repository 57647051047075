//  Color Palette

$palette: (
  // Brand Colors
  color--dark:                          #14192D,
  color--dark2:                         #252A2F,
  color--light:                         #FFFFFF,
  color--cta:                           #14192D,
  color--cta-rgb:                       '20, 25, 45',
  color--ctaDisabled:                   #D9D5FF,
  color--brand--highlight:              rgb(200, 55, 100),
  color--brand--highlight-hover:        rgb(200, 55, 100, 0.08),
  color--brand--ctaHover:               #fbeff3,
  color--brand--cta-hover:              #f1f1f2,
  color--brand--cta-hover-2:            #f1f9f3,
  color--slider:                        #f2f2f0,
  color--ctaHover:                      rgba(20, 25, 45, 0.06),
  color--menu--link:                   #B9B9B2,


// System Colors
  color--highlight:                     #FE3982,
  color--darkTint:                      #BEB9B4,
  color--darkTint-1:                    #BEB9B4,
  color--tint:                          #e1e1e1,
  color--tint-1:                        #E6E1D7,
  color--tintHover:                     #F3F3F0,
  color--lightTint:                     #f8f8f5,
  color--lightestTint:                  #F8F8F7,
  color--confirm:                       #55B96E,
  color--error:                         #f56978,
  color--warning:                       #f0c828,
  color--info:                          #4ba5dc,

  // Value colours
  color--wellbeing:                     #f07d64,
  color--wellbeing-rgb:                 '240, 125, 100',
  color--integrity:                     #cd78aa,
  color--integrity-rgb:                 '205, 120, 170',
  color--social:                        #37A5D2,
  color--social-rgb:                    '120, 180, 205',
  color--environmental:                 #96c86e,
  color--environmental-rgb:             '150, 200, 110',
  color--economic:                      #fcbc56,
  color--economic-rgb:                  '252, 188, 86',

  // Overlays
  color--lightOverlay:                  rgba(255, 255, 255, 0.8),
  color--darkOverlay:                   rgba(45, 45, 45, 0.8),

  // Shadows
  color--blackShadow:                   rgba(20, 25, 45, 0.16),
  color--tinyShadow:                    rgba(45, 45, 45, 0.04),
  color--ctaShadow:                     rgba(103, 89, 255, 0.16),

  // Gradients
  gradient--overlay-large:              linear-gradient(90deg, rgba(0, 0, 0, 0.75) 29%, rgba(0, 0, 0, 0) 64%),

  // The colors below are included for backwards compatibility
  // We should only use the color above, and eventually throw
  // all these away


  color--maroon:                        #652831, // used for value preview shadows and icons
  color--blackTint:                     rgba(0, 0, 0, 0.3) // used for page header tints

);

@function c($color-name) {
  @return map-get($palette, $color-name);
}

//  Category Colors

:root {

  // Layout

  --color--page-background: #{c(color--lightTint)};
  --color--background-darkTint: #{c(color--darkTint)};
  --color--page-header-tint: #{c(color--blackTint)};
  --color--page-subheader-background: transparent;
  --color--background-light: #{c(color--light)};
  --color--background-tint: #{c(color--tint)};
  --color--background-tint-1: #{c(color--tint-1)};
  --color--background-lightTint: #{c(color--lightTint)};
  --color--background-lightestTint: #{c(color--lightestTint)};
  --color--background-dark: #{c(color--dark)};
  --color--background-cta: #{c(color--cta)};
  --color--background-ctaHover: #{c(color--ctaHover)};
  --color--background-brand--cta-hover: #{c(color--brand--cta-hover)};
  --color--background-brand--cta-hover-2: #{c(color--brand--cta-hover-2)};
  --color--background-cta-hover: #{c(color--brand--ctaHover)};
  --color--background-medium: #{c(color--lightTint)};
  --color--background-confirm: #{c(color--confirm)};
  --color--background-selected: #{c(color--ctaDisabled)};
  --color--background-cta-faded: rgba(#{c(color--cta-rgb)}, 0.08);
  --color--background-auth: #96505a;  // Special case outside palette
  --color--brand-highlight: #{c(color--brand--highlight)};
  --color--brand--highlight-hover: #{c(color--brand--highlight-hover)};


  // Text Colors

  --color--text: #{c(color--dark)};
  --color--text-main: #{c(color--dark2)};
  --color--text-dark: #{c(color--dark)};
  --color--text-light: #{c(color--light)};
  --color--text-faded: #{c(color--darkTint)};
  --color--text-link: #{c(color--cta)};
  --color--text-menu-link: #{c(color--menu--link)};
  --color--text-brand--highlight: #{c(color--brand--highlight)};
  --color--text-caption: #{c(color--highlight)};
  --color--text-confirm: #{c(color--confirm)};
  --color--text-info: #{c(color--info)};
  --color--text-error: #{c(color--error)};
  --color--text-warning: #{c(color--warning)};
  --color--text-lightGrey: #{c(color--lightTint)};
  --color--text-darkGrey: #{c(color--darkTint)};

  // Value Colors

  --color--value-wellbeing: #{c(color--wellbeing)};
  --color--value-wellbeing-faded: rgba(#{c(color--wellbeing-rgb)}, 12%);
  --color--value-integrity: #{c(color--integrity)};
  --color--value-integrity-faded: rgba(#{c(color--integrity-rgb)}, 12%);
  --color--value-social: #{c(color--social)};
  --color--value-social-faded: rgba(#{c(color--social-rgb)}, 12%);
  --color--value-environmental: #{c(color--environmental)};
  --color--value-environmental-faded: rgba(#{c(color--environmental-rgb)}, 12%);
  --color--value-economic: #{c(color--economic)};
  --color--value-economic-faded: rgba(#{c(color--economic-rgb)}, 12%);

  // Border Colors
  --color--border-lightTint: #{c(color--lightTint)};
  --color--border-light: #{c(color--light)};
  --color--border-darkTint: #{c(color--darkTint)};
  --color--border-tint: #{c(color--tint)};
  --color--border-tint-1: #{c(color--tint-1)};

  --color--border-cta: #{c(color--cta)};


  // Navigation

  --color--navigation-background: #{c(color--light)};
  --color--navigation-text: #{c(color--dark)};
  --color--navigation-text-active: #{c(color--cta)};
  --color--navigation-underline-background: #{c(color--cta)};

  --color--submenu-background: #{c(color--light)};
  --color--submenu-border: #{c(color--lightTint)};
  --color--submenu-active: #{c(color--cta)};

  // Icon Colors

  --color--icon-light: #{c(color--light)};
  --color--icon-cta: #{c(color--cta)};
  --color--icon-dark: #{c(color--dark)};
  --color--icon-darkTint: #{c(color--darkTint)};
  --color--icon-tint: #{c(color--tint)};
  --color--icon-warning: #{c(color--warning)};
  --color--icon-value: #{c(color--maroon)};
  --color--icon-value-wellbeing: var(--color--value-wellbeing);
  --color--icon-value-integrity: var(--color--value-integrity);
  --color--icon-value-social: var(--color--value-social);
  --color--icon-value-environmental: var(--color--value-environmental);
  --color--icon-value-economic: var(--color--value-economic);
  --color--icon-confirm: #{c(color--confirm)};
  --color--icon-info: #{c(color--info)};
  --color--icon-disabled: #{c(color--ctaDisabled)};
  --color--icon-inactive: #{c(color--darkTint)};
  --color--icon-lightMaroon: #{c(color--highlight)};
  --color--icon-error: #{c(color--error)};
  --color--icon-close: #{c(color--darkTint)};
  --color--icon-social: #{c(color--social)};

  // Avatar

  --color--avatar-background: #{c(color--lightTint)};

  // Value Preview

  --color--value-background-footer: #{c(color--light)};
  --color--value-preview-footer-border: #{c(color--tint)};
  --color--value-background: #{c(color--light)};
  --color--value-background-brand-cta: #{c(color--dark)};
  --color--value-preview-background-subscribers: #{c(color--highlight)};
  --color--value-preview-text-subscribers: #{c(color--light)};
  --color--value-subvalue-community: #{c(color--cta)};

  // Value Preview
  --color--value-family-intro-background: #{c(color--lightTint)};
  --color--value-family-background: #{c(color--light)};

  // Value Modal
  --color--value-modal-control-icon-border:  #{c(color--tint)};
  --color--value-modal-control-icon-border-active:  #{c(color--confirm)};

  // Grid

  --color--grid-vanish-start: opacify(#{c(color--lightTint)}, 0);
  --color--grid-vanish-end: #{c(color--lightTint)};

  // Category Button

  --color--category-button-text: #{c(color--light)};

  // Modal
  --color--modal-overlay-background: #{c(color--darkOverlay)};
  --color--modal-background: #{c(color--light)};

  // Slider

  --color--slider-background: #{c(color--slider)};
  --color--slider-fill-current: #{c(color--cta)};
  --color--slider-fill-current-highlight: #{c(color--brand--highlight)};
  --color--slider-fill-current-confirm: #{c(color--confirm)};
  --color--slider-fill-default: #{c(color--cta)};
  --color--slider-knob-fill: #{c(color--light)};
  --color--slider-knob-fill-active: #{c(color--cta)};
  --color--slider-knob-border: #{c(color--cta)};
  --color--slider-knob-border-active: #{c(color--cta)};

  // Button

  --color--button-primary-background: #{c(color--cta)};
  --color--button-primary-border: #{c(color--cta)};
  --color--button-primary-text: #{c(color--light)};
  --color--button-secondary-background: transparent;
  --color--button-secondary-border: #{c(color--cta)};
  --color--button-secondary-text: #{c(color--cta)};
  --color--button-brand-cta: #{c(color--dark)};
  --color--button-brand-light: #{c(color--light)};
  --color--button-brand-highlight: #{c(color--brand--highlight)};
  --color--button-negative-background: #{c(color--error)};
  --color--button-negative-border: #{c(color--error)};
  --color--button-negative-text: #{c(color--light)};

  // Forms

  --color--form-border-inactive: #{c(color--tint)};
  --color--form-border-active: #{c(color--cta)};
  --color--form-border-disabled: #{c(color--tint)};
  --color--form-border-error: #{c(color--error)};
  --color--form-border-notch-active: #{c(color--light)};

  --color--form-background-autofill: rgba(#{c(color--cta-rgb)}, 8%);

  --color--form-text: #{c(color--dark)};
  --color--form-text-inactive: #{c(color--tint)};
  --color--form-text-active: #{c(color--cta)};
  --color--form-text-disabled: #{c(color--darkTint)};
  --color--form-text-error: #{c(color--error)};

  // --- The form variables below are included for
  // --- backwards compatibility. We should only use
  // --- the generic ones above and eventually
  // --- throw the ones below away.

  --color--form-date-input-separator: #{c(color--tint)};

  --color--form-input-background: #{c(color--light)};
  --color--form-input-text: #{c(color--dark)};
  --color--form-input-text-inactive: #{c(color--tint)};
  --color--form-input-text-error: #{c(color--error)};
  --color--form-input-border: #{c(color--dark)};
  --color--form-input-border-top: #{c(color--light)};
  --color--form-input-border-inactive: #{c(color--tint)};
  --color--form-input-border-error: #{c(color--error)};
  --color--form-input-border-active: #{c(color--confirm)};
  --color--form-input-label: #{c(color--dark)};
  --color--form-input-label-active: #{c(color--confirm)};
  --color--form-input-label-inactive: #{c(color--tint)};
  --color--form-input-label-error: #{c(color--error)};
  --color--form-input-floating-label-background: #{c(color--light)};
  --color--form-input-placeholder-active: #{c(color--darkTint)};


  --color--form-dropdown-background: #{c(color--lightTint)};
  --color--form-dropdown-bottom-background: #{c(color--light)};
  --color--form-dropdown-border: #{c(color--tint)};
  --color--form-dropdown-border-active: #{c(color--cta)};
  --color--form-dropdown-label: #{c(color--dark)};
  --color--form-dropdown-label-active: #{c(color--confirm)};
  --color--form-dropdown-footer-label: #{c(color--confirm)};
  --color--form-dropdown-footer-border: #{c(color--lightTint)};
  --color--form-dropdown-arrow: #{c(color--dark)};
  --color--form-dropdown-text: #{c(color--dark)};
  --color--form-dropdown-bottom-border: #{c(color--lightTint)};
  --color--form-dropdown-bottom-text: #{c(color--dark)};
  --color--form-dropdown-bottom-text-selected: #{c(color--cta)};
  --color--form-dropdown-bottom-selected-background: #{c(color--ctaHover)};
  --color--form-dropdown-floating-label-background: #{c(color--light)};

  --color--form-dropdown-selection-border-active: #{c(color--confirm)};
  --color--form-dropdown-selection-placeholder-active: #{c(color--darkTint)};
  --color--form-dropdown-selection-bottom-border: #{c(color--tint)};

  // Chip
  --color--chip-label-tag: #{c(color--ctaDisabled)};
  --color--chip-active: #{c(color--cta)};
  --color--chip-active-disabled: #{c(color--ctaDisabled)};

  // --- The field chip variables below are included for
  // --- backwards compatibility. We should only use
  // --- the generic ones above (for chip) and eventually
  // --- throw the ones below away.
  // * ----8<-----8<------8<------8<--------------*/

  --color--field-chip-border: #{c(color--tint)};
  --color--field-chip-border-active: #{c(color--cta)};
  --color--field-chip-label: #{c(color--dark)};

    // ----8<-----8<------8<------8<--------------

  // Selection Dropdown Panel
  --color--selection-dropdown-border: #{c(color--tint)};
  --color--selection-dropdown-item-selected-background: #{c(color--ctaHover)};

  // Checkbox
  --color--checkbox-label: #{c(color--dark)};
  --color--checkbox-label-inactive: #{c(color--darkTint)};
  --color--checkbox-background: #{c(color--light)};
  --color--checkbox-background-cheched: #{c(color--lightTint)};
  --color--checkbox-background-icon: #{c(color--ctaDisabled)};
  --color--checkbox-background-icon-inactive: #{c(color--lightTint)};
  --color--checkbox-border: #{c(color--tint)};
  --color--checkbox-border-active: #{c(color--confirm)};
  --color--checkbox-tickbox-background-active: #{c(color--confirm)};
  --color--checkbox-tickbox-border-active: #{c(color--confirm)};


  // Radio
  --color--radio-background: #{c(color--light)};
  --color--radio-background-inactive: #{c(color--tint)};
  --color--radio-background-selected: #{c(color--lightTint)};
  --color--radio-background-selected-active: #{c(color--cta)};
  --color--radio--border: #{c(color--tint)};
  --color--radio--border-active: #{c(color--cta)};
  --color--radio-label: #{c(color--dark)};
  --color--radio-label-inactive: #{c(color--darkTint)};
  --color--radio-icon-background: #{c(color--ctaDisabled)};
  --color--radio-icon-background-inactive: #{c(color--lightTint)};

  // Switch
  --color--switch-background: #{c(color--ctaDisabled)};
  --color--switch-background-active: #{c(color--confirm)};
  --color--switch-background-inactive: #{c(color--tint)};
  --color--switch-border-inactive: #{c(color--tint)};
  --color--switch-handle-background: #{c(color--light)};
  --color--switch-handle-border: #{c(color--ctaDisabled)};
  --color--switch-handle-border-active: #{c(color--confirm)};

  // Options
  --color--options-background-active: #{c(color--ctaDisabled)};
  --color--options-item-active: #{c(color--lightTint)};

  // Shadows

  --shadow--raised-low: 0 .125rem .125rem 0 #{c(color--blackShadow)};
  --shadow--value-active: 0 0 .625rem 0 #{c(color--maroon)};

  --shadow--box-drag: 0px .125rem .75rem #{c(color--blackShadow)};
  --shadow--box-tiny: 0px .25rem .5rem #{c(color--tinyShadow)};
  --shadow--box-small: 0px .25rem .75rem #{c(color--blackShadow)};
  --shadow--box-medium: 0px .375rem 1.125rem #{c(color--blackShadow)};
  --shadow--box-large: 0px .5rem 1.5rem #{c(color--blackShadow)};
  --shadow--cta-shadow: 0px .25rem .75rem #{c(color--blackShadow)};

  --shadow-chip-drag: 0px .25rem .75rem #{c(color--dark)};
  --shadow-top-bar: 0 .0625rem .1875rem .0625rem #d8d8d8;

  // Gradients

  --gradient--overlay-large: #{c(gradient--overlay-large)};

  // Aspirations Graph

  --color--ag-description-background: #{c(color--light)};

}
