$font-family-sans-serif:      "work-sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-family-monospace:       "source-serif-4-small-text", Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base:            $font-family-sans-serif !default;
$font-family-heading:         $font-family-monospace !default;



$sizes: xs, pico, micro, tiny, small, medium, large, xlarge, xl, huge, lhuge, xhuge, avatar;
$avatarSizes: small, xsmall, forum, medium, large, contributor, contributor-small, profile;
$colors: error, warning, lightMaroon, disabled, info, darkTint, inactive, cta, dark, tint, light, social, value, confirm, value-wellbeing, value-social, value-environmental, value-economic, value-integrity, cvalue-wellbeing, cvalue-social, cvalue-environmental, cvalue-economic, cvalue-integrity;
$strokeWidths: 1, 2, 3, 4, 5;
$categories: wellbeing, social, environmental, economic, integrity;

// Profile Experiece Types
$experienceType: work, other, volunteering, education;
