:root {
  --size--font-base: 1rem;

  --size--letter-spacing-tight: .00625rem; // 0.1px
  --size--letter-spacing-close: .0125rem; // 0.2px
  --size--letter-spacing-wide: .03125rem; // 0.5px
  --size--letter-spacing-wider: .046875rem; // 0.75px
  --size--letter-spacing-xwider: .0625rem; // 1px


  --size--font-weight-regular: 300;
  --size--font-weight-semi-bold: 400;
  --size--font-weight-bold: 600;
  --size--font-weight-black: 700;


  --size--font-heading-1: 36px;
  --size--lineheight-heading-1: 44px;
  --size--font-heading-2: 28px;
  --size--lineheight-heading-2: 36px;
  --size--font-heading-3: 24px;
  --size--lineheight-heading-3: 32px;
  --size--font-heading-4: 1.25rem;
  --size--lineheight-heading-4: 1.75rem;
  --size--font-heading-5: 1rem;
  --size--lineheight-heading-5: 1.625rem;
  --size--font-heading-6: 0.875rem;
  --size--lineheight-heading-6: 1.5rem;
  --size--font-heading-7: 0.75rem;
  --size--lineheight-heading-7: 1.25rem;
  --size--font-heading-8: 0.6875rem;
  --size--lineheight-heading-8: 1.125rem;

  --size--font-body-huge: 4.25rem;
  --size--lineheight-body-huge: 1.18;

  --size--font-body-xlarge: 24px;
  --size--lineheight-body-xlarge: 32px;
  --size--font-body-large: 20px;
  --size--lineheight-body-large: 28px;
  --size--font-body-regular: 16px;
  --size--lineheight-body-regular: 26px;
  --size--font-body-small: 14px;
  --size--lineheight-body-small: 24px;
  --size--font-body-xsmall: 12px;
  --size--lineheight-body-xsmall: 1.25rem;
  --size--font-body-tiny: 0.6875rem;
  --size--lineheight-body-tiny: 1.125rem;

  --size--font-body-eyebrow-regular: 0.8125rem;
  --size--lineheight-body-eyebrow-regular: 1.375rem;
  --size--font-body-eyebrow-small: 11px;
  --size--lineheight-body-eyebrow-small: 18px;

  --size--font-interactive-button: 13px; //0.8125rem;
  --size--font-interactive-button-link: .875rem;
  --size--lineheight-interactive-button: 1.5rem;

  --size--font-interactive-text-link: 14px;
  --size--lineheight-interactive-text-link: 24px;

  --size--font-form-label: 0.875rem;
  --size--lineheight-form-label: 1.375rem;

}

@mixin typo--h1 {
  font-family: $font-family-heading;
  font-weight: var(--size--font-weight-black);
  font-size: var(--size--font-heading-1);
  line-height: var(--size--lineheight-heading-1);
}

h1 {
  @include typo--h1;
}

@mixin typo--h2 {
  font-family: $font-family-heading;
  font-weight: var(--size--font-weight-black);
  font-size: var(--size--font-heading-2);
  line-height: var(--size--lineheight-heading-2);
}

h2 {
  @include typo--h2;
}

@mixin typo--h3 {
  font-family: $font-family-heading;
  font-weight: var(--size--font-weight-black);
  font-size: var(--size--font-heading-3);
  line-height: var(--size--lineheight-heading-3);
}

h3 {
  @include typo--h2;
}

h4 {
  font-family: $font-family-heading;
  font-weight: var(--size--font-weight-black);
  font-size: var(--size--font-heading-4);
  line-height: var(--size--lineheight-heading-4);
}

h5 {
  font-weight: var(--size--font-weight-black);
  font-size: var(--size--font-heading-5);
  line-height: var(--size--lineheight-heading-5);
}

@mixin typo--navigation-text {
  font-size: var(--size--font-body-xsmall);
  line-height: var(--size--lineheight-body-xsmall);
}

@mixin typo--navigation-text-active {
  font-size: var(--size--font-body-xsmall);
  line-height: var(--size--lineheight-body-xsmall);
  font-weight: bold !important;
}

@mixin typo--subvalue {
  font-size: var(--size--font-body-regular);
  line-height: var(--size--lineheight-body-regular);
  font-weight: bold;
}

@mixin typo--value-header {
  font-size: var(--size--font-body-xlarge);
  line-height: var(--size--lineheight-body-xlarge);
  font-weight: bold;
}

@mixin typo--header-super {
  font-size: 1.88rem;
  font-weight: bold;
  color: var(--color--text-light);
}

@mixin typo--header-super-mobile {
  font-size: 1.5rem !important;
}

@mixin typo--tiny {
  font-size: var(--size--font-body-tiny);
}

@mixin typo--body-huge {
  font-size: var(--size--font-body-huge);
  line-height: var(--size--lineheight-body-huge);
  font-weight: 700;
}

@mixin typo--body-large {
  font-size: var(--size--font-body-large);
  line-height: var(--size--lineheight-body-large);
  font-weight: var(--size--font-weight-semi-bold);
}

.typo-body-large {
  @include typo--body-large;
}

@mixin typo--body-xlarge {
  font-size: var(--size--font-body-xlarge);
  line-height: var(--size--lineheight-body-xlarge);
  font-weight: var(--size--font-weight-semi-bold);
}

@mixin typo--body-regular {
  font-size: var(--size--font-body-regular);
  line-height: var(--size--lineheight-body-regular);
  font-weight: var(--size--font-weight-semi-bold);
}

.typo-body-xlarge {
  @include typo--body-xlarge;
}

.typo-body-regular {
  @include typo--body-regular;
}

@mixin typo--body-small {
  font-size: var(--size--font-body-small);
  line-height: var(--size--lineheight-body-small);
  font-weight: var(--size--font-weight-semi-bold);
}

.typo-body-small {
  @include typo--body-small;
}

@mixin typo--body-xsmall {
  font-size: var(--size--font-body-xsmall);
  line-height: var(--size--lineheight-body-xsmall);
}

.typo-body-xsmall {
  @include typo--body-xsmall;
}

.numbers {
  font-family: $font-family-base;
}

@mixin typo--body-eyebrow-regular {
  font-size: var(--size--font-body-eyebrow-regular);
  line-height: var(--size--lineheight-body-eyebrow-regular);
  color: var(--color--brand-highlight);
  font-weight: bold;
}

.typo-eyebrow-regular {
  @include typo--body-eyebrow-regular;
}

@mixin typo--body-eyebrow-small {
  font-size: var(--size--font-body-eyebrow-small);
  line-height: var(--size--lineheight-body-eyebrow-small);
  color: var(--color--text-confirm);
  font-weight: var(--size--font-weight-black);
}

.typo-eyebrow-small {
  @include typo--body-eyebrow-small;
}


@mixin typo--base {
  font-size: var(--size--font-base);
}

@mixin type--small-bold {
  font-size: 0.63em;
  font-weight: 600;
}

@mixin typo--value-description {
  font-size: var(--size--font-body-regular);
  line-height: var(--size--lineheight-body-regular);
}

@mixin typo--value-subscribers {
  font-size: var(--size--font-body-tiny);
}

@mixin typo--interactive-button {
  font-size: var(--size--font-interactive-button);
  line-height: var(--size--lineheight-interactive-button);
}

@mixin typo--interactive-text-link {
  font-size: var(--size--font-interactive-text-link);
  font-weight: var(--size--font-weight-bold);
  line-height: var(--size--lineheight-interactive-text-link);
  cursor: pointer;
  color: var(--color--text-link);
}
