$metrics: (
  spacing--x0-25:                       .0625rem, // 1px
  spacing--x05:                         .125rem,  // 2px
  spacing--x0-75:                       .1875rem, // 3px
  spacing--x1:                          .25rem,   // 4px
  spacing--x1-25:                       .3125rem, // 5px
  spacing--x1-5:                        .375rem,  // 6px
  spacing--x2:                          .5rem,    // 8px
  spacing--x2-25:                       .5625rem, // 9px
  spacing--x2-5:                        .625rem,  // 10px
  spacing--x2-75:                       .6875rem, // 11px
  spacing--x3:                          .75rem,   // 12px
  spacing--x3-25:                       .8125rem, // 13px
  spacing--x3-50:                       .875rem,  // 14px
  spacing--x3-75:                       .9375rem, // 15px
  spacing--x4:                          1rem,     // 16px
  spacing--x4-5:                        1.125rem, // 18px
  spacing--x5:                          1.25rem,  // 20px
  spacing--x5-5:                        1.375rem, // 22px
  spacing--x5-75:                       1.4375rem, // 23px
  spacing--x6:                          1.5rem,   // 24px
  spacing--x6-25:                       1.5625rem,// 25px
  spacing--x6-5:                        1.625rem, // 26px
  spacing--x7:                          1.75rem,  // 28px
  spacing--x7-5:                        1.875rem, // 30px
  spacing--x7-75:                       1.9375rem,// 31px
  spacing--x8:                          2rem,     // 32px
  spacing--x8-25:                       2.0625rem,// 33px
  spacing--x8-5:                        2.125rem, // 34px
  spacing--x8-75:                       2.1875rem,// 35px
  spacing--x9:                          2.25rem,  // 36px
  spacing--x9-5:                        2.375rem, // 38px
  spacing--x10:                         2.5rem,   // 40px
  spacing--x11:                         2.75rem,  // 44px
  spacing--x11-5:                       2.875rem, // 46px
  spacing--x12:                         3rem,     // 48px
  spacing--x12-25:                      3.125rem, // 50px
  spacing--x13:                         3.25rem,  // 52px
  spacing--x14:                         3.5rem,   // 56px
  spacing--x14-5:                       3.625rem, // 58px
  spacing--x15:                         3.75rem,  // 60px
  spacing--x16:                         4rem,     // 64px
  spacing--x17:                         4.25rem,  // 68px
  spacing--x18:                         4.5rem,   // 72px
  spacing--x18-5:                       4.625rem, // 74px
  spacing--x19-5:                       4.875rem, // 78px
  spacing--x20:                         5rem,     // 80px
  spacing--x20-5:                       5.125rem, // 82px
  spacing--x22:                         5.5rem,   // 88px
  spacing--x22-75:                      5.6875rem,// 91px
  spacing--x23:                         5.75rem,  // 92px
  spacing--x24:                         6rem,     // 96px
  spacing--x25:                         6.25rem,  // 100px
  spacing--x26:                         6.5rem,   // 104px
  spacing--x27:                         6.75rem,  // 108px
  spacing--x27-25:                      7.125rem,  // 114px
  spacing--x34:                         8.5rem,   // 136px
  spacing--x36:                         9rem,     // 144px
  spacing--x39:                         9.75rem,  // 156px
  spacing--x40:                         10rem,    // 160px
  spacing--x41:                         10.25rem, // 164px
  spacing--x46:                         11.5rem,  // 184px
  spacing--x50:                         12.5rem,  // 200px
  spacing--x52:                         13rem,    // 208px
  spacing--x52-5:                       13.125rem,// 210px
  spacing--x59:                         14.75rem, // 236px
  spacing--x62:                         15.5rem,  // 248px
  spacing--x63:                         15.75rem, // 252px
  spacing--x63-25:                      16.125rem, // 258px
  spacing--x65-75:                      16.4375rem,// 263px
  spacing--x69:                         17.25rem,  // 276px
  spacing--x70:                         17.5rem,  // 280px
  spacing--x71:                         17.75rem, // 284px
  spacing--x72:                         18rem,    // 288px
  spacing--x76:                         19rem,    // 304px
  spacing--x79-75:                      19.9375rem,// 319px
  spacing--x80:                         20rem,    // 320px
  spacing--x90:                         22.5rem,  // 360px
  spacing--x98:                         24.5rem,  // 392px
  spacing--x109:                        27.25rem, // 436px
  spacing--x124:                        31rem,    // 496px
  spacing--x126-25:                     31.5625rem,// 505px
  spacing--x130:                        32.5rem,  // 520px
  spacing--x132:                        33rem,    // 528px
  spacing--x140:                        35rem,    // 560px
  spacing--x150:                        37.5rem,  // 600px
  spacing--x154:                        38.5rem,  // 616px
  spacing--x158:                        39.5rem,  // 632px
  spacing--x167:                        41.75rem, // 668px
  spacing--x168:                        42rem,    // 672px
  spacing--x176:                        44rem,    // 704px
  spacing--x182:                        45.5rem,  // 728px
  spacing--x320:                        80rem     // 1280px

);

@function getSpacing($spacing-name) {
  @return map-get($metrics, $spacing-name);
}


:root {

  // spacing
  --size--spacing-x0-25:  #{getSpacing(spacing--x0-25)};
  --size--spacing-x05:    #{getSpacing(spacing--x05)};
  --size--spacing-x0-75:  #{getSpacing(spacing--x0-75)};
  --size--spacing-x1:     #{getSpacing(spacing--x1)};
  --size--spacing-x1-25: #{getSpacing(spacing--x1-25)};
  --size--spacing-x1-5:   #{getSpacing(spacing--x1-5)};
  --size--spacing-x2:     #{getSpacing(spacing--x2)};
  --size--spacing-x2-25:  #{getSpacing(spacing--x2-25)};
  --size--spacing-x2-5:   #{getSpacing(spacing--x2-5)};
  --size--spacing-x2-75:  #{getSpacing(spacing--x2-75)};
  --size--spacing-x3:     #{getSpacing(spacing--x3)};
  --size--spacing-x3-25:  #{getSpacing(spacing--x3-25)};
  --size--spacing-x3-50:  #{getSpacing(spacing--x3-50)};
  --size--spacing-x3-75:  #{getSpacing(spacing--x3-75)};
  --size--spacing-x4:     #{getSpacing(spacing--x4)};
  --size--spacing-x4-5:   #{getSpacing(spacing--x4-5)};
  --size--spacing-x5:     #{getSpacing(spacing--x5)};
  --size--spacing-x5-5:   #{getSpacing(spacing--x5-5)};
  --size--spacing-x5-75:   #{getSpacing(spacing--x5-75)};
  --size--spacing-x6:     #{getSpacing(spacing--x6)};
  --size--spacing-x6-25:  #{getSpacing(spacing--x6-25)};
  --size--spacing-x6-5:   #{getSpacing(spacing--x6-5)};
  --size--spacing-x7:     #{getSpacing(spacing--x7)};
  --size--spacing-x7-5:   #{getSpacing(spacing--x7-5)};
  --size--spacing-x7-75:  #{getSpacing(spacing--x7-75)};
  --size--spacing-x8:     #{getSpacing(spacing--x8)};
  --size--spacing-x8-25:  #{getSpacing(spacing--x8-25)};
  --size--spacing-x8-5:   #{getSpacing(spacing--x8-5)};
  --size--spacing-x8-75:  #{getSpacing(spacing--x8-75)};
  --size--spacing-x9:     #{getSpacing(spacing--x9)};
  --size--spacing-x9-5:   #{getSpacing(spacing--x9-5)};
  --size--spacing-x10:    #{getSpacing(spacing--x10)};
  --size--spacing-x11:    #{getSpacing(spacing--x11)};
  --size--spacing-x11-5:  #{getSpacing(spacing--x11-5)};
  --size--spacing-x12:    #{getSpacing(spacing--x12)};
  --size--spacing-x12-25: #{getSpacing(spacing--x12-25)};
  --size--spacing-x13:    #{getSpacing(spacing--x13)};
  --size--spacing-x14:    #{getSpacing(spacing--x14)};
  --size--spacing-x14-5:  #{getSpacing(spacing--x14-5)};
  --size--spacing-x15:    #{getSpacing(spacing--x15)};
  --size--spacing-x16:    #{getSpacing(spacing--x16)};
  --size--spacing-x17:    #{getSpacing(spacing--x17)};
  --size--spacing-x18:    #{getSpacing(spacing--x18)};
  --size--spacing-x18-5:  #{getSpacing(spacing--x18-5)};
  --size--spacing-x19-5:  #{getSpacing(spacing--x19-5)};
  --size--spacing-x20:    #{getSpacing(spacing--x20)};
  --size--spacing-x20-5:  #{getSpacing(spacing--x20-5)};
  --size--spacing-x22:    #{getSpacing(spacing--x22)};
  --size--spacing-x22-75: #{getSpacing(spacing--x22-75)};
  --size--spacing-x23:    #{getSpacing(spacing--x23)};
  --size--spacing-x24:    #{getSpacing(spacing--x24)};
  --size--spacing-x25:    #{getSpacing(spacing--x25)};
  --size--spacing-x26:    #{getSpacing(spacing--x26)};
  --size--spacing-x27:    #{getSpacing(spacing--x27)};
  --size--spacing-x27-25:    #{getSpacing(spacing--x27-25)};
  --size--spacing-x34:    #{getSpacing(spacing--x34)};
  --size--spacing-x36:    #{getSpacing(spacing--x36)};
  --size--spacing-x39:    #{getSpacing(spacing--x39)};
  --size--spacing-x40:    #{getSpacing(spacing--x40)};
  --size--spacing-x41:    #{getSpacing(spacing--x41)};
  --size--spacing-x46:    #{getSpacing(spacing--x46)};
  --size--spacing-x50:    #{getSpacing(spacing--x50)};
  --size--spacing-x52:    #{getSpacing(spacing--x52)};
  --size--spacing-x52-5:  #{getSpacing(spacing--x52-5)};
  --size--spacing-x59:    #{getSpacing(spacing--x59)};
  --size--spacing-x62:    #{getSpacing(spacing--x62)};
  --size--spacing-x63:    #{getSpacing(spacing--x63)};
  --size--spacing-x65-75: #{getSpacing(spacing--x65-75)};
  --size--spacing-x69:    #{getSpacing(spacing--x69)};
  --size--spacing-x70:    #{getSpacing(spacing--x70)};
  --size--spacing-x71:    #{getSpacing(spacing--x71)};
  --size--spacing-x72:    #{getSpacing(spacing--x72)};
  --size--spacing-x76:    #{getSpacing(spacing--x76)};
  --size--spacing-x79-75: #{getSpacing(spacing--x79-75)};
  --size--spacing-x80:    #{getSpacing(spacing--x80)};
  --size--spacing-x90:    #{getSpacing(spacing--x90)};
  --size--spacing-x98:    #{getSpacing(spacing--x98)};
  --size--spacing-x109:   #{getSpacing(spacing--x109)};
  --size--spacing-x124:   #{getSpacing(spacing--x124)};
  --size--spacing-x126-25:#{getSpacing(spacing--x126-25)};
  --size--spacing-x130:   #{getSpacing(spacing--x130)};
  --size--spacing-x132:   #{getSpacing(spacing--x132)};
  --size--spacing-x140:   #{getSpacing(spacing--x140)};
  --size--spacing-x150:   #{getSpacing(spacing--x150)};
  --size--spacing-x154:   #{getSpacing(spacing--x154)};
  --size--spacing-x158:   #{getSpacing(spacing--x158)};
  --size--spacing-x167:   #{getSpacing(spacing--x167)};
  --size--spacing-x168:   #{getSpacing(spacing--x168)};
  --size--spacing-x176:   #{getSpacing(spacing--x176)};
  --size--spacing-x182:   #{getSpacing(spacing--x182)};
  --size--spacing-x320:   #{getSpacing(spacing--x320)};


  // Navigation

  --size--navigation-height: 78px;
  --size--navigation-height-scrolling: 3.375rem;
  --size--navigation-height-mobile: 3.63rem;
  --size--navigation-underline-height: 0.26rem;
  --size--navigation-link-spacing: 2rem;
  --size--navigation-link-margin: #{getSpacing(spacing--x2)};
  --size--navigation-side-padding: 6.75rem;

  --size-navigation-profile-overlay-mobile-bottom: 4.25rem;
  --size--navigation-profile-top: 5rem;
  --size--navigation-profile-right: 6.75rem;
  --size--navigation-profile-width: 312px;
  --size--navigation-profile-padding: #{getSpacing(spacing--x5)} 0;
  --size--navigation-profile-mobile-padding-top: 2.5rem;
  --size--navigation-profile-border-radius: #{getSpacing(spacing--x2)};
  --size--navigation-profile-item-padding: 0 #{getSpacing(spacing--x5)};
  --size--navigation-profile-content-padding: .9375rem 0;
  --size--navigation-profile-content-text-padding-left: #{getSpacing(spacing--x3)};
  --size--navigation-profile-content-mobile-padding: #{getSpacing(spacing--x6)} 0;
  --size--navigation-profile-slide-width: #{getSpacing(spacing--x12)};
  --size--navigation-profile-slide-height: #{getSpacing(spacing--x1)};
  --size--navigation-profile-slide-top: #{getSpacing(spacing--x3)};
  --size--navigation-profile-slide-border-radius: #{getSpacing(spacing--x1)};

  --size--navigation-profile-user-padding: 0 #{getSpacing(spacing--x6)} #{getSpacing(spacing--x6)};

  --size--submenu-height: 2.88rem;
  --size--submenu-padding: 0 6.75rem;
  --size--submenu-padding-mobile: 0 1.125rem;
  --size--submenu-spacing: #{getSpacing(spacing--x12)};

  // Logo Size

  --size--logo-width: 110px;
  --size--logo-height: 30px;

  // Icon Sizes

  --size--icon-xs: #{getSpacing(spacing--x1)};
  --size--icon-pico: #{getSpacing(spacing--x2)};
  --size--icon-micro: 0.625rem;
  --size--icon-tiny: #{getSpacing(spacing--x3)};
  --size--icon-small: #{getSpacing(spacing--x4)};
  --size--icon-avatar: #{getSpacing(spacing--x9)};
  --size--icon-medium: #{getSpacing(spacing--x5)};
  --size--icon-large: #{getSpacing(spacing--x6)};
  --size--icon-xlarge: #{getSpacing(spacing--x7)};
  --size--icon-xl: 2rem;
  --size--icon-huge: #{getSpacing(spacing--x12)};
  --size--icon-lhuge: #{getSpacing(spacing--x14)};
  --size--icon-xhuge: #{getSpacing(spacing--x18)};
  --size--icon-xxhuge: #{getSpacing(spacing--x24)};
  --size--icon-input: 1.125rem;

  // Avatar Sizes

  --size--avatar-xsmall: #{getSpacing(spacing--x5)};
  --size--avatar-small: 2rem;
  --size--avatar-forum: #{getSpacing(spacing--x9)};
  --size--avatar-preview: #{getSpacing(spacing--x40)};
  --size--avatar-medium: #{getSpacing(spacing--x24)};
  --size--avatar-contributor: #{getSpacing(spacing--x26)};
  --size--avatar-contributor-small: #{getSpacing(spacing--x14)};
  --size--avatar-profile: #{getSpacing(spacing--x36)};
  --size--avatar-large: 18rem;

  // Slider Metrics

  --size--slider-base: #{getSpacing(spacing--x4)};

  // Value Preview Metrics

  --size--value-preview-border-radius: 0.56rem;
  --size--value-padding-horizontal: #{getSpacing(spacing--x9)};
  --size--value-padding-vertical: 2rem;
  --size--value-spacing-vertical: #{getSpacing(spacing--x6)};
  --size--value-spacing-horizontal: #{getSpacing(spacing--x6)};
  --size--value-minimum-width: 18rem;
  --size--value-categories-spacing-horizontal: 0.1rem;
  --size--value-footer-padding-horizontal: #{getSpacing(spacing--x6)};
  --size--value-footer-padding-vertical: #{getSpacing(spacing--x5)};
  --size--subvalue-margin: #{getSpacing(spacing--x2)};
  --size--subvalue-name-margin: #{getSpacing(spacing--x2)};
  --size--value-preview-zoom-factor: 1.1;
  --size--value-subscibers-height: #{getSpacing(spacing--x6)};
  --size--value-subscibers-padding: 0.63rem;
  --size--value-icons-top-spacing: 1.375rem;
  --size--value-preview-footer-border: 0.06rem;


  // Category Button

  --size--category-button-height: #{getSpacing(spacing--x9)};
  --size--category-button-padding: 0 #{getSpacing(spacing--x3)} 0 #{getSpacing(spacing--x1)};
  --size--category-button-spacing: #{getSpacing(spacing--x3)};
  --size--category-button-text-margin: #{getSpacing(spacing--x2)};
  --size--category-button-text: 0.69rem;
  --size--category-button-border-width: 0.125rem;

  // Page Definitions

  --size--header-height: 14.75rem;
  --size--header-short-height: 4.38rem;
  --size--header-margin-bottom: 1.125rem;
  --size--layout-padding: #{getSpacing(spacing--x4)} 6.75rem;
  --size--layout-padding-mobile: #{getSpacing(spacing--x4)} 0rem;
  --size--generic-padding: #{getSpacing(spacing--x24)} 6.75rem;

  --size--section-padding: #{getSpacing(spacing--x24)} #{getSpacing(spacing--x24)} 13.5rem;
  --size--section-padding-mobile: #{getSpacing(spacing--x18)} #{getSpacing(spacing--x5)};
  --size--section-content-padding: 0rem #{getSpacing(spacing--x12)};

  // Forms

  --size--form-base-height: #{getSpacing(spacing--x12)};
  --size--form-base-width: #{getSpacing(spacing--x80)};
  --size--form-base-margin: #{getSpacing(spacing--x2)};
  --size--form-base-border: #{getSpacing(spacing--x05)};
  --size--form-base-border-radius: #{getSpacing(spacing--x1)};
  --size--form-icon: #{getSpacing(spacing--x4)};


  // Mobile Flyover

  --size--flyover--header-height: #{getSpacing(spacing--x14-5)};
  --size--flyover--footer-height: #{getSpacing(spacing--x22)};

  /** The form variables below are included for
   * backwards compatibility. We should only use
   * the generic ones above and eventually
   * throw the ones below away.
   * ----8<-----8<------8<------8<--------------
   */

  --size--form-input-height: #{getSpacing(spacing--x12)};
  --size--form-input-border-radius: #{getSpacing(spacing--x1)};
  --size--form-input-padding: 0 #{getSpacing(spacing--x3)};
  --size--form-input-border-width: 0.13rem;

  // ----8<-----8<------8<------8<--------------

  // Search

  --size--search-width: 24.5rem;
  --size--search-height: #{getSpacing(spacing--x12)};
  --size--search-padding: 0 #{getSpacing(spacing--x3)};
  --size--search-border-radius: #{getSpacing(spacing--x1)};
  --size--search-border-width: 0.13rem;

  // Button

  --size--button-height: #{getSpacing(spacing--x12)};
  --size--button-width: 12rem;
  --size--button-tiny-width: 6.5625rem;
  --size--button-tiny-height: 2.25rem;
  --size--button-small-width: 8rem;
  --size--button-middle: #{getSpacing(spacing--x36)};
  --size--button-xm-width: #{getSpacing(spacing--x40)};
  --size--button-huge: #{getSpacing(spacing--x46)};
  --size--button-xl-width: 16rem;
  --size--button-border-width: 0.13rem;
  --size--button-border-radius: #{getSpacing(spacing--x1)};
  --size--button-border-radius-6: #{getSpacing(spacing--x6)};
  --size--button-hover-border-radius: #{getSpacing(spacing--x3)};
  --size--button-padding: 0 #{getSpacing(spacing--x12)};
  --size--button-spacing: #{getSpacing(spacing--x3)};
  --size--button-loader-size: #{getSpacing(spacing--x2)};
  --size--button-loader-child-position: #{getSpacing(spacing--x1)};

  // Dropdown

  --size--dropdown-height: #{getSpacing(spacing--x12)};
  --size--dropdown-width: #{getSpacing(spacing--x34)};
  --size--dropdown-border-radius: #{getSpacing(spacing--x1)};
  --size--dropdown-bottom-border-radius: #{getSpacing(spacing--x2)};
  --size--dropdown-bottom-padding: #{getSpacing(spacing--x1)} 0;
  --size--dropdown-bottom-margin-top: #{getSpacing(spacing--x2)};
  --size--dropdown-border-width: 0.13rem;
  --size--dropdown-padding: 0 #{getSpacing(spacing--x5)};
  --size--dropdown-label-padding: 0 #{getSpacing(spacing--x2)};
  --size--dropdown-label-left: #{getSpacing(spacing--x3)};
  --size--dropdown-label-top: -0.7rem;

  // Chip
  --size--chip-min-width: #{getSpacing(spacing--x15)};
  --size--chip-height: #{getSpacing(spacing--x9)};
  --size--chip-padding: .375rem #{getSpacing(spacing--x3)};
  --size--chip-border-radius: #{getSpacing(spacing--x4-5)};
  --size--chip-label-padding-right: #{getSpacing(spacing--x2)};
  --size--chip-icon-padding-left: #{getSpacing(spacing--x2)};

  // Field Chip
  --size--field-chip-height: #{getSpacing(spacing--x7-5)};
  --size--field-chip-padding: .1875rem #{getSpacing(spacing--x3)};

  // --- The chip selection variables below are included for
  // --- backwards compatibility. We should only use
  // --- the generic ones and eventually
  // --- throw the ones below away.
  // * ----8<-----8<------8<------8<--------------*/

  --size--chip-selection-floating-label-left: #{getSpacing(spacing--x5)};
  --size--chip-selection-floating-label-top: #{getSpacing(spacing--x4)};
  --size--chip-selection-floating-label-active-left: #{getSpacing(spacing--x4)};
  --size--chip-selection-floating-label-active-padding: 0rem #{getSpacing(spacing--x2)};
  --size--chip-selection-input-filled-height: 1.875rem;
  --size--chip-selection-input-filled-width: #{getSpacing(spacing--x15)};
  --size--chip-selection-input-dropdown-max-height: 12.25rem;
  --size--chip-selection-input-dropdown-margin: .3125rem 0 0;
  --size--chip-selection-input-dropdown-border-radius: .3125rem;
  --size--chip-selection-input-dropdown-border-width: .125rem;
  --size--chip-selection-input-dropdown-item-min-height: #{getSpacing(spacing--x12)};
  --size--chip-selection-input-dropdown-item-padding: 0rem 1.125rem;
  --size--chip-selection-chip-input-border-radius: #{getSpacing(spacing--x1)};
  --size--chip-selection-chip-input-border-width: .125rem;
  --size--chip-selection-chip-input-padding: .6875rem #{getSpacing(spacing--x5)};

  // ----8<-----8<------8<------8<--------------


  // Checkbox
  --size--checkbox-min-width: 4.25rem;
  --size--checkbox-height-large: #{getSpacing(spacing--x24)};
  --size--checkbox-height-small: #{getSpacing(spacing--x12)};
  --size--checkbox-height-tiny: 1.13rem;
  --size--checkbox-padding-large: #{getSpacing(spacing--x5)};
  --size--checkbox-padding-small: 0.6875rem;
  --size--checkbox-border-radius: #{getSpacing(spacing--x1)};
  --size--checkbox-border-width: 0.125rem;
  --size--checkbox-icon-padding: #{getSpacing(spacing--x3)};
  --size--checkbox-content-padding-left-large: #{getSpacing(spacing--x5)};
  --size--checkbox-content-padding-left-small: #{getSpacing(spacing--x3)};
  --size--checkbox-content-padding-left-tiny: #{getSpacing(spacing--x2)};
  --size--checkbox-tickbox-width: #{getSpacing(spacing--x6)};
  --size--checkbox-tickbox-height: #{getSpacing(spacing--x6)};

  // Radio
  --size--radio-min-width: 4.25rem;
  --size--radio-height-large: #{getSpacing(spacing--x24)};
  --size--radio-height-small: #{getSpacing(spacing--x12)};
  --size--radio-padding-large: #{getSpacing(spacing--x5)};
  --size--radio-padding-small: 0.6875rem;
  --size--radio-border-radius: #{getSpacing(spacing--x1)};
  --size--radio-border-width: 0.125rem;
  --size--radio-icon-padding: #{getSpacing(spacing--x3)};
  --size--radio-content-padding-left-large: #{getSpacing(spacing--x5)};
  --size--radio-content-padding-left-small: #{getSpacing(spacing--x3)};
  --size--radio-tickbox-width: #{getSpacing(spacing--x6)};
  --size--radio-tickbox-height: #{getSpacing(spacing--x6)};

  // Input
  --size--input-width: 18.75rem;
  --size--input-height: #{getSpacing(spacing--x12)};
  --size--input-leading: 1.63;
  --size--input-tracking: .00625rem;
  --size--input-padding: 0 #{getSpacing(spacing--x12)} 0 #{getSpacing(spacing--x5)};
  --size--input-border-radius: #{getSpacing(spacing--x1)};
  --size--input-border-width: .125rem;
  --size--input-label-leading: 1.57;
  --size--input-label-tracking: .0125rem;
  --size--input-label-padding: 0 #{getSpacing(spacing--x2)};
  --size--input-label-top: -#{getSpacing(spacing--x3)};
  --size--input-label-left: #{getSpacing(spacing--x3)};
  --size--input-icon-width: #{getSpacing(spacing--x4)};
  --size--input-icon-height: #{getSpacing(spacing--x4)};
  --size--input-icon-right: #{getSpacing(spacing--x5)};
  --size--input-icon-tick-top: 1.0625rem;
  --size--input-icon-close-top: 1.125rem;
  --size--input-info-padding-top: #{getSpacing(spacing--x2)};

  // Dropdown
  --size--options-max-height: 12.25rem;

  // Textarea
  --size--textarea-padding-top: .5rem;
  --size--textarea-notch-padding-top: .6875rem;

  // Switch

  --size--switch-width: 2.5rem;
  --size--switch-height: #{getSpacing(spacing--x4)};
  --size--switch-border-radius: #{getSpacing(spacing--x2)};
  --size--switch-handle-width: #{getSpacing(spacing--x6)};
  --size--switch-handle-height: #{getSpacing(spacing--x6)};
  --size--switch-handle-border-width: .125rem;
  --size--switch-icon-left: #{getSpacing(spacing--x1)};
  --size--switch-icon-top: #{getSpacing(spacing--x1)};

  // Modal
  --size--modal-confirm-width: 24.5rem;
  --size--modal-confirm-height: 17.125rem;
  --size--modal-small: 18.75rem;
  --size--modal-base-width: 23.5rem;

  --size--modal-medium: 37.5rem;
  --size--modal-default: 38.5rem;
  --size--modal-large: 50rem;
  --size--modal-xlarge: 71.25rem;

  --size-modal-icon-top: #{getSpacing(spacing--x9)};
  --size-modal-icon-right: #{getSpacing(spacing--x9)};
  --size-modal-padding: #{getSpacing(spacing--x9)} 0;
  --size-modal-border-radius: #{getSpacing(spacing--x2)};
}

@mixin page--header {
  height: var(--size--header-height);
  width: 100%;
  background-size: cover;
  background-position: center;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-bottom: var(--size--header-margin-bottom);
  .header--tint {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color--page-header-tint);
  }
  .header-content {
    height: 100%;
    color: var(--color--text-light);
  }
}

@mixin page--header-short {
  height: var(--size--header-short-height);
  width: 100%;
  position: relative;
  padding-bottom: 2.44rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background-color: var(--color--nav-background);
}

@mixin page--subheader {
  width: 100%;
  height: #{getSpacing(spacing--x14)};
  padding: var(--size--layout-padding);
}

@mixin page--content {
  padding: var(--size--layout-padding);
  .page--pusher {
    display: flex;
    justify-content: center;
    align-items: flex-end;

    .grid--monkey {
      width: 5rem;
      height: 5rem;
      background-image: var(--image--monkey);
      background-size: cover;
    }
  }
}

@mixin grid--vanish() {
  background-image: linear-gradient(
      to bottom,
      var(--color--grid-vanish-start),
      var(--color--grid-vanish-end) 75%,
      var(--color--grid-vanish-end)
  );
  width: 100%;
  height: 19.81rem;
  opacity: 1;
  pointer-events: none;
  transition: opacity var(--duration--slow) ease;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  bottom: 0;
  z-index: 2;
  &.hidden {
    opacity: 0;
  }
}

@mixin max-width-container {
  margin: 0 auto;
  max-width: var(--size--spacing-x320);
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-12 {
  margin-bottom: 12px;
}

.mb-48 {
  margin-bottom: 48px;
}
